/* AboutMe.css */

/* Set font size and family for the entire page */
body {
  font-size: 16px; /* Adjust the base font size as per your design */
  font-family: Arial, sans-serif; /* Set a preferred font family */
}

.about-me-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* background-color: #563d2d; */
  background-color: transparent;
}

.about-section {
  margin-bottom: 30px;
  background-color: pink;
  text-align: justify; /* Justify text for better alignment */
}

.header {
  margin-bottom: 20px;
  text-align: justify; /* Justify text for better alignment */
}

.header h2 {
  font-size: 1.5rem; /* Adjust header (h2) font size */
  font-family: inherit; /* Inherit font family from body */
}

.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: white;
  color: black;
  border: black 3px;
  text-decoration: none;
  border-radius: 5px;
  transition: none; /* Remove button decoration on hover */
  font-family: inherit; /* Inherit font family from body */
}

.button:hover {
  text-decoration: none;
  background-color: pink;
  color: black; /* Change text color on hover */
  border: 2px solid black; /* Add black border on hover */
  transition: border-color 0.3s ease; /* Smooth transition for border color change */
}


.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px; /* Adjust height as needed */
}

.card {
  width: 100%;
  max-width: 600px; /* Adjust maximum width of the image card */
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.image-slide {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.footer {
  text-align: center;
  padding: 10px;
  background-color: #f8f9fa;
  border-top: 1px solid #dee2e6;
  font-family: inherit; /* Inherit font family from body */
}

/* Added styles for hobbies list */
.hobbies-list {
  list-style-type: none;
  padding-left: 0;
  margin-top: 10px;
  text-align: left;
  font-style: italic;
}

.hobbies-list li {
  margin-bottom: 8px;
  font-size: 1.6rem; /* Adjust font size of list items */
  font-family: inherit; /* Inherit font family from body */
}

/* General styles for the about page */
.about-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.about-image {
  max-width: 50%;
  height: auto;
  margin-bottom: 20px;
}

.about-text {
  text-align: center;
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  line-height: 1.5;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .about-page {
    flex-direction: row;
  }

  .about-image {
    max-width: 100%;
    margin-bottom: 0;
  }

  .about-text {
    margin-left: 20px;
  }
}

/* General styles for the about page */
.about-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.about-image {
  max-width: 50%;
  height: auto;
  margin-bottom: 20px;
}

.about-text {
  text-align: center;
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  line-height: 1.5;
}

/* Work experience styles */
.work-experience {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
}

.work-item {
  width: calc(33% - 20px); /* Adjusted for spacing between items */
  margin-bottom: 20px;
  box-sizing: border-box;
}

.company-logo {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.work-details {
  /* Inherit font styles from parent */
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  /* Adjust work experience layout for smaller screens */
  .work-experience {
    flex-direction: column;
    align-items: center;
  }

  .work-item {
    width: 100%;
  }
}