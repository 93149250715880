.header {
    text-align: center;
    padding: 32px;
    font-size: 5em;
}

.header p {
    font-size: 0.2em;
}

.gallery-row {
    display: -ms-flexbox;
    /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap;
    /* IE10 */
    flex-wrap: wrap;
    padding: 10 4px;
    padding-bottom: 300px;
}

image {
    width: 100%;
}

/* Create four equal columns that sits next to each other */
.gallery-column {
    -ms-flex: 25%;
    /* IE10 */
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
}

.gallery-column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
    .gallery-column {
        -ms-flex: 50%;
        flex: 50%;
        max-width: 50%;
    }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .column {
        -ms-flex: 100%;
        flex: 100%;
        max-width: 100%;
    }
}