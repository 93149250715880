body {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  color: #212529;
  font-size: 2em;
  text-align: left;
  background-image: url('./config/works/gezi.png'); /* Example path */
  /* background-size: cover; */
  /* background-repeat: space; */
  /* background-position: center center; */


  @media (max-width: 768px) {
    /* Adjust font size for medium screens (tablets) */
      font-size: 1.5em;
    
  }

  @media (max-width: 576px) {
    /* Adjust font size for small screens (phones) */
      font-size: 1.2em;
  }
}

/* Adjust font sizes for different screen sizes */
@media (max-width: 1200px) {
  p, html {
    font-size: 14px; /* Decrease font size for large screens */
  }
}

@media (max-width: 992px) {
  p,html {
    font-size: 12px; /* Further decrease font size for medium screens */
  }
}

@media (max-width: 768px) {
  p, html{
    font-size: 10px; /* Decrease font size for small screens (tablets) */
  }
}

@media (max-width: 576px) {
  p, html {
    font-size: 8px; /* Decrease font size for extra small screens (phones) */
  }
}



