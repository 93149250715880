.blog-container {
    margin: 100 auto;
    padding: 50px 50px 50px 50px;
    box-sizing: border-box;
}

/* Style the title of the Blog Page */
.blog-detail-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin: 60px 0;
}

/* Style the list of blogs */
.blog-list {
    list-style: none;
    padding: 50px;
}

.blog-list-item {
    /* margin: 10px 0; */
    width: 100%;
    height: 100%;
    max-width: 100%;
    transform: translateY(-50px);
    overflow: scroll;
    overflow-x: hidden;
    transform: translate3d(0px, -111px, 0px);
    /* padding: 50px; */
    /* background-color: #f5f5f5; */
    /* border: 1px solid #ddd; */
    /* border-radius: 5px; */
    /* Rounded corners */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    /* Box shadow for a subtle lift */
    /* transition: background-color 0.3s ease; */
}

.blog-list-item a {
    text-decoration: none;
    color: #212529;
    font-weight: bold;
}

.blog-list-item a:hover {
    /* color: #007bff; */
    /* Change the color on hover */
}

/* BlogPost.css */

.blof-container {
    padding: 20px;
    /* Adjust the padding as needed */
}

.blog-content {
    padding: 50px;
}

.content {
    text-align: center;
    padding: 20px;
}

.content h1 {
    font-size: 24px;
    font-weight: bold;
}

.image-container {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    /* align-items: center; */
    /* margin: 0 auto; */
    /* padding: 20px; */
}

.blog-card {
    overflow: hidden;
    /* box-shadow: 0 4px 8px 0 rgba(241, 239, 239, 0.2); */
    /* margin: 8px; */
}

.blog-profile-image {
    /* width: 30vw;
    height: 30vw; */
    max-width: 100%;
    max-height: 100%;
    /* object-fit: contian; */
}

.blog-title {
    text-decoration: underline;
    font-size: calc(80px + 1vw);
    color: black;
}

.content p {
    font-size: 28px;
    line-height: 2.5;
    padding: 20px;
}

.blog-column {
    float: left;
    text-align: center;
    width: 50%;
    margin-bottom: 16px;
    padding: 0 8px;
}

.about-section {
    padding: 50px;
    background-color: white;
    margin-bottom: 20px; /* Bottom margin */
}

.container {}

.container::after,
.blog-row::after {
    content: "";
    clear: both;
    display: table;
}

.title {
    text-align: center;
    font-size: 30;
}

.button:hover {
    background-color: rgb(137, 131, 131);
}

@media screen and (max-width: 650px) {
    .blog-column {
        width: 100%;
        display: block;
    }
}