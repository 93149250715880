.work-page {
    padding: 20px;
}

.work-experience {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center; /* Center align items horizontally */
}

.work-item {
    display: flex;
    flex-direction: column; /* Stack items vertically on smaller screens */
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: calc(33.33% - 20px); /* Adjust width based on your design */
    max-width: 300px; /* Adjust max-width based on your design */
}

.company-logo {
    width: 80px; /* Adjust width of the logo */
    height: 80px; /* Adjust height of the logo */
    object-fit: contain;
    margin-bottom: 15px; /* Space between logo and details */
    border-radius: 50%; /* Rounded shape for the logo */
}

.work-details {
    flex: 1;
}

h3 {
    margin-top: 0;
    font-size: 1.2rem;
}

p {
    margin: 5px 0;
    font-size: 0.9rem;
    color: #666;
}

@media (max-width: 768px) {
    .work-item {
        width: 100%; /* Full width on smaller screens */
        max-width: none; /* No max width on smaller screens */
    }
}
