/* Sticky Menu Styles */
.navbar-collapse {
    position: sticky;
    top: 0; /* Adjust top value as needed */
    height: calc(100vh - 56px); /* Adjust height based on your navbar height */
    overflow-y: auto; /* Enable scrolling for the menu if it exceeds viewport height */
  }
  
  @media (max-width: 991.98px) {
    .navbar-collapse {
      position: static; /* Reset for mobile view */
      height: auto; /* Reset height for mobile view */
      overflow-y: visible; /* Reset overflow for mobile view */
    }
  }

  