@import url("https://rsms.me/inter/inter.css");
@import url("https://fonts.googleapis.com/css2?family=Antonio:wght@600&display=swap");

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  overscroll-behavior-y: none;
  font-family: 'Poppins', sans-serif;
  flex-direction: column;
}

a {
  text-decoration: none;
  color: inherit;
  text-align: center;
}


@media screen and (max-width: 568px) {
  .full {
    visibility: hidden;
    display: none;
  }
}

footer
{
  background-color: white;
  height: 60px;
}

.navbar-logo {
  display: flex;
  align-items: center; /* Center align items vertically */
  justify-content: center; /* Center align items horizontally */
}

.logo-image {
  height: 30px; /* Adjust the height to make the logo smaller */
  width: auto; /* Maintain aspect ratio */
  transition: transform 0.3s ease; /* Add a transition effect */
}

.navbar-logo:hover .logo-image {
  transform: scale(1.1); /* Slightly enlarge the logo on hover */
}

.navbar {
  background-color: transparent !important; /* Set the background color to white */
}

.navbar .container-fluid {
  display: flex;
  align-items: center; /* Center align items vertically */
  justify-content: space-between; /* Space out items horizontally */
}

.navbar .navbar-brand {
  display: flex;
  align-items: center; /* Center align items vertically */
}

.navbar .navbar-toggler {
  border: none; /* Remove border from the toggler */
}

.navbar .navbar-nav .nav-link {
  color: #212529; /* Text color for nav links */
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.navbar .navbar-nav .nav-link:hover {
  font-weight: bold; /* Make nav links bold */
}




